import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import { Link } from "react-scroll";

const MobileMenu = ({ show, onClose }) => {
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("menu-expand") ||
      target.classList.contains("menu-expand")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 1000);
        }
      });
    }
  };
  return (
    <div className={`offcanvas-modal ${show ? "show" : ""}`}>
      <div className="offcanvas-dialog">
        <div className="menu-content">
          <div className="offcanvas-header">
            <Logo
              classOption="offcanvas-logo d-inline-block"
              image={`${process.env.PUBLIC_URL}/images/logo/jobmatrix.png`}
            />
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>

          <nav id="offcanvasNav" className="offcanvas-menu">
            <ul>
              <li>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <Link to="about">
                  <p>About</p>
                </Link>
              </li>
              <li>
                <Link to="service">
                  <p>Services</p>
                </Link>
              </li>
              <li>
                <Link to="contact">
                  <p>Contact</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileMenu;
